header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  min-height: 250px;
  font-size: 130%;
  background: #f1ebf1;
  // background-color: #00e091;
  // background-image: radial-gradient(
  //     circle at 50% top,
  //     rgb(252, 252, 20) 15%,
  //     rgba(84, 90, 182, 0) 75%
  //   ),
  //   radial-gradient(
  //     circle at right top,
  //     rgb(251, 98, 98) 15%,
  //     rgba(121, 74, 162, 0) 80%
  //   );
  // background-repeat: no-repeat;
  // background-size: 100% 1000px;
  font-weight: 600;
  ul li a {
    color: #333;
    font-size: 18px;
  }
}
.rectangles {
  display: flex;
  .parallelogram {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    transform: skew(-20deg);
    font-size: 48px;
    color: #002f6c;
    font-weight: 600;
    text-shadow: 3px 2px white;
  }
  .rect-green {
    background-color: #078930;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .rect-yellow {
    background-color: #fcdd09;
  }
  .rect-red {
    background-color: #da121a;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.association-name {
  display: relative;
  font-weight: 600;
  padding-bottom: 0px;
  text-transform: uppercase;
  border-bottom: 10px solid #002f6c;
  text-shadow: 3px 2px white;
}

/* === TITLES === */

.page-title {
  margin-bottom: 5px;
  // padding: 10px;
  text-transform: uppercase;
  text-align: center;
  font-family: var(--font-family-heading);
  color: #333;
  font-weight: 900;
  // background-color: rgba(243, 182, 182, 0.25);
  // background-color: rgba(0, 224, 145, 0.1);
  // margin: 15px auto;
  background-color: #00e091;
  background-image: radial-gradient(
      circle at 50% top,
      rgb(252, 252, 20) 15%,
      rgba(84, 90, 182, 0) 75%
    ),
    radial-gradient(
      circle at right top,
      rgb(251, 98, 98) 15%,
      rgba(121, 74, 162, 0) 80%
    );
  background-repeat: no-repeat;
  background-size: 100% 1000px;
  padding: 10px;
  border-radius: 100px;
}

@media (max-width: 768px) {
  body {
    font-size: 1rem !important;
  }
  header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    min-height: 250px;
    font-size: 130%;
    // background-color: rgba(251, 98, 98, 0.75);
    // background-image: radial-gradient(
    //     circle at 75% top,
    //     rgba(0, 224, 145, 0.75) 18%,
    //     rgba(84, 90, 182, 0) 0%
    //   ),
    //   radial-gradient(
    //     circle at right top,
    //     rgb(252, 252, 20) 30%,
    //     rgba(121, 74, 162, 0) 5%
    //   );
    // background-repeat: no-repeat;
    // background-size: 100% 1000px;

    font-weight: 600;
    ul li a {
      color: #333;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
  .nav-wrapper {
    display: flex;
  }
  .outer {
    width: 100px;
    height: 100px;
  }
  .middle {
    width: 80px;
    height: 80px;
  }
  .inner {
    width: 60px;
    height: 60px;
    background-color: white;
    font-size: 0.75rem !important;
  }

  .page-title {
    background-color: #00e091;
    background-image: radial-gradient(
        circle at left top,
        #00e091 5%,
        rgba(121, 74, 162, 0) 20%
      ),
      radial-gradient(
        circle at 25% top,
        rgb(252, 252, 20) 15%,
        rgba(84, 90, 182, 0) 50%
      ),
      radial-gradient(
        circle at right top,
        rgb(251, 98, 98) 5%,
        rgba(121, 74, 162, 0) 75%
      );
    font-size: 0.95rem !important;
  }
}
