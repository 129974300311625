.card {
  height: auto;
  display: flex;
  // justify-content: space-between;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.2rem;
    display: block;
  }

  p {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-family: 'Roboto' !important;
    letter-spacing: 0.0625em !important;
    color: rgb(99, 96, 96);
    border-radius: 3px;
    line-height: 170%;
    font-size: 1rem !important;
  }
}
.card > * {
  width: 50%;
  margin-right: 50px;
}
.card-date {
  color: #222;
  font-size: 0.75rem !important;
  display: block;
}
.description {
  padding: 0px 50px;
  margin: 10px 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: 'Roboto' !important;
  letter-spacing: 0.0625em !important;
  color: rgb(99, 96, 96);
  border-radius: 3px;
  /* -webkit-box-shadow: 0 0 0 0.1px #797777;
  box-shadow: 0 0 0 0.1px #797777; */
  line-height: 170%;
  font-size: 1rem !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.card-image {
  display: block;
  max-width: 15%;
  min-width: 15%;
  /* border: 1px solid var(--color-primary); */
}

.card-title {
  margin: 5px auto;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}
.card-subtitle {
  display: inline-block;
  font-size: 22px !important;
  font-family: 'Popins' !important;
  font-weight: 300 !important;
  margin: 2px auto;
}

.card-detail {
  background: white;
  margin: 0;
}
.card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  /* background: #eadef5; */
}
.card-image {
  display: block;
  /* width: 10%;
  width: 10%; */
  border-radius: 50%;
}

.card-category {
  margin: 25px auto;
}
.card-category > h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}
.card-detail .card-header {
  padding: 0;
}
.card-body {
  padding: 30px;
}
.card-footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.card-wrapper {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: background-color 0.3s ease;
  transition-delay: 0.2s;
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 0 0.1px #bfbfbf;
  margin: 25px auto;
  // font-size: 120%;
  &:hover {
    background-color: #00e091;
    background-color: rgb(209, 199, 240);
    background-color: rgba(165, 138, 255, 0.25);

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  }
}

.user-avatar {
  display: inline-block;
  /* width: 35px; */
  /* height: 35px; */
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  background: rgb(106, 109, 106);
}
small {
  font-weight: 300;
  font-family: 'Lora';
}
span.key {
  border: 1px solid var(--color-primary-lighter);
  border-radius: 20px;
  margin-right: 10px;
  padding: 5px 20px;
}
hr {
  border: 3px solid #eadef5;
  border: 1px solid #00e091;
  margin: 10px auto;
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.contacts > small {
  font-weight: 300;
  font-size-adjust: 0.75rem;
  font-family: 'Popins';
  margin: 0 !important;
  padding: 0;
}
.categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  margin: 50px auto;
}

.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25%;
  min-width: 25%;
  height: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 25px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: background-color 0.5s ease;
  transition-delay: 0.2s;
  border-radius: 0.25rem;
  box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-image: linear-gradient(
    to right,
    rgba(240, 240, 162, 0.45),
    rgba(255, 196, 196, 0.2)
  );

  // background-color: #eff16d;
  h1 {
    font-weight: 900;
    font-size: 36px;
  }

  small {
    color: rgb(99, 96, 96);
    font-size: 1rem !important;
    font-weight: 400 !important;
    display: none;
  }
  i {
    font-size: 50px;
    color: #00e091;
  }
  &:hover {
    background-color: #00e091;
    border: none;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    color: white;
    &:hover h1 {
      color: white !important;
    }
    &:hover small {
      display: block;
      color: white;
      font-weight: 300 !important;
    }
    &:hover i {
      color: white;
    }
  }
}
.hero {
  min-width: 100%;
  min-height: 10vh;
  // background: #cfc8c8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 10px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  // border: 15px solid #002F6C !important;
  // color: #002f6c !important;
  background: #002f6c;
  // color: white;
  // border: 2px solid #002f6c;

  background: #e9ecef;
  color: #002f6c !important;

  // box-shadow: 0px 0px 5px 1px #e3e9f0;

  p {
    font-size: 32px;
    text-align: center;
    // color: white;
    font-family: 'Popins';
  }
  animation: slide-left 0.5s forwards;
}
.apply-form-membership {
  display: inline-block;
  background-color: #00e091;
  padding: 15px;
  border-radius: 15px;
  margin: 30px;
  font-family: 'Popins';
  transition: background-color 0.3s;
  animation: slide-left 0.5s forwards;
  font-size: 1.5rem;
  border-radius: 100px;
  margin-bottom: 25px;
}
.apply-form-membership:hover {
  background-color: rgba(0, 224, 145, 0.75);
}

@keyframes slide-left {
  0% {
    opacity: 0;
    left: -100;
    transform: translateX(-80px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* === RESPONSIVE === */
@media (max-width: 768px) {
  body {
  }
  .header-wrapper {
    margin: 25px 0 0 0;
  }

  .header-wrapper,
  .main-wrapper,
  .footer-wrapper {
    margin: auto;
    width: 100%;
  }

  .header-wrapper__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .header-wrapper__nav ul {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-wrapper__nav ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
    padding: 10px 50px;
  }

  img {
    display: block;
    /* max-width: 100%;
    min-width: 100%; */
    height: auto;
    text-align: center;
    /* margin: 10px auto; */
  }

  .web-tech__images img {
    display: block;
    max-width: 75%;
    min-width: 75%;
    height: auto;
    margin: 15px auto;
  }

  article {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .profile-header,
  .profile-body {
    padding: 25px;
  }

  .row {
    display: flex;
    flex-direction: column;
  }
  .row > div {
    width: 100%;
  }
  .description {
    padding: 5px;
    margin: 3px auto;
  }

  .student-status {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .student-status > button,
  .student-status > small {
    width: 100%;
    padding: 0;
    padding: 15px;
    text-align: center;
  }

  .single-student {
    margin: 0 0 25px 0;
  }
  .profile-header small {
    font-size: 0.5em;
  }
  .profile-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: 10px;
  }

  .card-image {
    display: block;
    min-width: 40%;
    /* border: 1px solid var(--color-primary); */
  }
  .header-wrapper,
  .main-wrapper,
  .footer-wrapper {
    padding: 0 5px;
    font-size: 1rem;
  }

  .card {
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    img {
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
      border-radius: 0.2rem;
      display: block;
    }

    p {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      font-family: 'Roboto' !important;
      letter-spacing: 0.0625em !important;
      color: rgb(99, 96, 96);
      border-radius: 3px;
      line-height: 170%;
      font-size: 1rem !important;
    }
  }
  .card > * {
    width: 100%;
    margin-right: 50px;
  }

  .categories {
    display: flex;
    flex-direction: column;
    justify-self: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100vw;
    margin: 50px auto;
  }
  .category {
    width: 90%;
    height: 300px;
    margin-right: 30px;
    margin-bottom: 30px;
    padding: 25px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: background-color 0.5s ease;
    transition-delay: 0.2s;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 0.1px #bfbfbf;
    box-shadow: 0px 10px 1px #ddd, 0 10px 20px #ccc;

    p {
      color: rgb(99, 96, 96);
      font-weight: 300 !important;
      display: none;
      font-size: 0.75rem !important;
    }
  }
  .category:hover {
    border: none;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    color: white;
    &:hover h1 {
      font-weight: 900;
      color: white !important;
    }
    &:hover p {
      display: block;
      color: white;
      font-weight: 100 !important;
    }
  }
  .hero {
    min-width: 100%;
    min-height: 10vh;
    background: #cfc8c8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    padding: 10px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    // border: 15px solid #002F6C !important;
    color: #002f6c !important;
    background: #002f6c;
    background: #e9ecef;

    p {
      font-size: 1.25rem;
      text-align: center;
      // color: white;
      font-family: 'Popins';
    }
  }
  .apply-form-membership {
    display: inline-block;
    margin-top: 25px;
    background: #00e091;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 25px;
    font-family: 'Popins';
    width: 95%;
    margin: auto;
    text-align: center;
    font-size: 1.15rem;
  }
}
