.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.gallery__item {
  width: 100% !important;
}

.gallery__img {
  max-width: 100% !important;
  max-height: 100% !important;
  min-width: 100% !important;
  max-height: 100% !important;
  display: block;
}
.gallery__img:hover {
  transform: scale(1.015);
}
.img-full {
  width: 25%;
}
.gallery-image-full-size {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  background-color: #212124;
  //  background-color: #b1adad;
}
.single-img-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 65vh !important;
  margin: auto;
  img {
    min-width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    display: block;
    margin: auto;
  }
}
.gallery-text-section {
  background-color: white;
  padding: 30px;
  color: black;
}
