/* === FOOTER === */
/* footer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 250px;
  background: #f1ebf1;
  background: rgba(121, 74, 162, 1);
  align-items: center;
  text-align: center;
  color: white;
} */

/* === FOOTER === */
footer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 500px;
  background: #f1ebf1;
  align-items: center;
  text-align: center;
  color: #222;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 28px;
    font-weight: 900;
  }
  ul li {
    list-style: none;
    color: black;
  }
  ul li a {
    color: black;
    text-decoration: none;
  }
  padding: 15px;
}
.footer-menu > div {
  h2 {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.336%;
  text-align: left;
}
small {
  font-weight: 16px;
  font-family: Montserrat;
  font-weight: 300;
  padding-bottom: 10px;
}
.arrow {
  display: flex;
  justify-content: flex-end;
  font-size: 50px;
  a,
  a:active,
  a:visited {
    color: purple;
    color: rgb(251, 133, 80);
  }
}

@media (max-width: 768px) {
  footer {
    height: 725px;
  }
  .footer-menu {
    flex-direction: column;
  }
  .footer-menu > div {
    width: 100%;
  }
}
