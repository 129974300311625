/* 
=== code block ===
 */
.about {
  margin: auto 15px;
}
.about-title {
  font-weight: 600;
  font-family: 'Popins';
  text-transform: uppercase;
}
pre {
  font-family: Consolas, Monaco, andale mono, monospace;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid rgb(240, 237, 237);
  border-radius: 3px;
  min-width: 325px;
  max-width: 450px;
  color: rgb(167, 167, 167);
}

pre:hover {
  color: rgb(139, 191, 235);
}

/*=== LINKS ===*/
.link {
  text-decoration: none;
  border-bottom: 1px solid #3b2f63;
  padding: 5px 10px;
  color: rgb(85, 85, 85);
}

.link:hover {
  background: #3b2f63;
  color: white;
}

/* UL FOR LIST */
.ul-list {
  position: relative;
  z-index: 10;
}

.list {
  list-style: none;
  margin-left: 5px;
  line-height: 40px;
  font-family: 'Roboto';
}

.list::before {
  position: absolute;
  left: 0px;
  content: '';
  display: inline-block;
  width: 35px;
  height: 35px;
  background-image: url(../images/favicons/favicon-32x32.png);
  background-size: cover;
}
