.socialsWrapper {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin: 5px auto;
  display: flex;
  padding: 3px;
  height: 60px;

  div {
    line-height: 100%;
  }

  i {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .socialsWrapper {
    width: 100% !important;
    margin: 10px auto;
  }
}
