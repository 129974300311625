/* ICONS */
.icons > a {
  display: inline-block;
  width: 50%;
}

.fa-trash-alt,
.fa-edit {
  font-size: 28px;
  color: var(--color-primary);
}

.fa-edit:hover {
  color: rgb(113, 187, 247);
}

.fa-trash-alt:hover {
  color: rgb(236, 112, 99);
}

.icons {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}
.fa-check-circle {
  color: #00e091;
  font-size: 22px;
}

.fa-chevron-circle-right,
.fa-chevron-circle-left {
  font-size: 60px;
  color: #cccccc;
  cursor: pointer;
}

.fa-chevron-circle-right:hover,
.fa-chevron-circle-left:hover {
  color:#e4e6eb;
  background-position: -25px;
  cursor: pointer;
}

