/* === GENERAL === */
* {
  box-sizing: border-box;
}
html {
  font-size: 62.25%;
}

:root {
  --font-family-heading: 'Oswald', serif;
  --font-family-heading: 'Oswald', serif;
  --font-family-body: 'Cardo', sans-serif;
  --font-family-body: 'Raleway', sans-serif;
  --color-primary: #3b2f63;
  --colr-primary: #002f6c;
  --color-primary-lighter: #794aa2;
  --color-primary-lighter: #002f6c;
  --color-text: #525c65;
  --background-body: rgba(226, 214, 214, 0.1);

  --font-size-regular-text: 18px;
  --font-size-heading: 26px;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  /* background: var(--background-body); */
  line-height: 1.5;
  font-family: var(--font-family-body);
  /* background: #f0f1f7; */
  font-size: 18px;
  font-family: Poppins;
  overflow-x: hidden;
}

.header-wrapper,
.main-wrapper,
.footer-wrapper {
  margin: auto;
  width: 95%;
}

/* === app wrapper === */
.wrapper {
  min-height: 100%;
  position: relative;
  letter-spacing: 1.25px;
}

/* === MAIN === */
main {
  padding-bottom: 500px;
  font-size: 130%;
}

h1,
h2,
h3,
/* h4, */
h5,
h6 {
  font-family: var(--font-family-heading);
  font-weight: 300;
  font-size: var(--font-size-heading);
  /* font-family: 'Popins'; */
  text-transform: uppercase;
}

p {
  font-family: var(--font-family-body);
  font-weight: 300;
}

a {
  text-decoration: none;
  color: black;
}

ul li a {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}

small {
  font-size: 18px !important;
  font-family: 'Roboto' !important;
}
.ql-editor {
  min-height:450px;
	/* border: none; */
	height: 150px;
	overflow: scroll;
}
.ql-editor img {
  max-width: 100%;
}
.description p img {
  width: 100%;
}
@media (max-width: 768px) {
  /* === MAIN === */
  main {
    padding-bottom: 725px;
    font-size: 130%;
    font-size: 1rem !important;
  }
}
