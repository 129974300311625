/*=== FORM  === */
form {
  height: 100%;
  margin: 30px auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row > div {
  width: 25%;
}
.col-2 {
  div {
    width: 45%;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
}

input,
textarea,
select {
  padding: 15px;
  margin: 15px 0;
  min-width: 250px;
  outline: none;
  font-size: 16px;
  border: 1px solid #eadef5;
  border: 1px solid #c1bac1;
  padding: 15px 20px;
  border-radius: 10px;
  line-height: inherit;
  color: #4e5255;
}

input + p {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
input:hover,
select:hover,
textarea:hover,
select:focus,
[for|='image']:hover,
[for|='image']:focus {
  background: #eadef5;
}

textarea {
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.file-upload {
  width: 25%;
}
inpt[type='file'] {
  width: 10%;
}

[for|='image'] {
  background: #eadef5;
  padding: 15px;
  border-radius: 25px;
}
label {
  font-size: 18px;
  font-weight: 300;
}

::placeholder,
option {
  color: rgba(0, 0, 0, 0.4);
}

.auth-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  width: 100%;
}

.auth-title {
  font-size: 20px;
  font-family: 'Oswald';
  font-weight: 100;
  text-align: center;
}
.signup-form-wrapper,
.signin-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-width: 380px;
  padding: 30px 100px;
  margin: 150px 0px;
  border-radius: 8px;
  overflow-x: hidden;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  h3,
  p {
    text-align: center;
    font-weight: 500;
  }

  .btn-auth {
    border-radius: 8px;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  form {
    width: 95%;
    padding: 0;
    margin: 0;
    margin: auto;
  }
  input,
  textarea {
    width: 100%;
  }
  .signup-form-wrapper,
  .signin-form-wrapper {
    width: 95%;
    min-width: 380px;
    padding: 15px 20px;
    margin: 3px 0px;

    h3,
    p {
      text-align: center;
      font-weight: 500;
    }
  }
}
