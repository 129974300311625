.support {
  font-weight: 500 !important;
}
.support-detail {
  font-weight: 600;
  color: black !important;
  margin: 0 auto;
  .description {
    padding: 50px;
    color: black !important;
    background-color: white;
    font-weight: 500 !important;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bank-account-box {
    margin: 0 auto;
    background-color: rgba(219, 192, 235, 0.25);
    border-right: 5px solid rgb(194, 139, 231);
    border-bottom: 5px solid rgb(194, 139, 231);

    small {
      color: white;
      display: block;
      padding: 0;
      margin: 0;
      font-weight: 500;
      color: black;
    }
    padding: 30px 30px 30px 50px;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.read-more {
  background: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
/* support page */

.image-count {
  color: #dfd7d7;
  background: rgba(167, 164, 164, 0.25);
  border-radius: 100%;
  font-size: 10px;
  padding: 10px;
  font-weight: 600;
  position: absolute;
  bottom: 80%;
  box-shadow: 0px 0px 10px 2px rgba(47, 47, 47, 0.2);
}

.single-img-full-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 45%;
  max-width: 100%;
  height: 80vh !important;
  margin: auto;
  img {
    min-width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    display: block;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .support-detail {
    width: 100% !important;
    .description {
      padding: 25px !important;
    }
  }
  .single-img-full-support {
    width: 100%;
  }
  .single-img-full {
    max-width: 100%;
    min-width: 100%;
  }
  .order-1 {
    // order:1;
  }
  .order-2 {
    // order:2;
    //   display:flex;
  }
  .order-3 {
    // order:2;
    // display:flex;
  }
  .gallery-image-full-size {
    width: 100%;
    padding: 0px;
    flex-direction: column;
    // background-color: #b1adad;
  }
  .fa-chevron-circle-right,
  .fa-chevron-circle-left {
    font-size: 40px;
    color: #cccccc;
    cursor: pointer;
    margin: 5px auto;
  }
  .image-count {
    bottom: 60%;
  }
}
