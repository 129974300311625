.btn {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 50px;
  text-align: center;
  cursor: pointer;

  background: #00e091;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  color: white;
  border: 2px solid #00e091;
  outline: none;
}
.btn:active,
.btn:focus {
  border: none;
  border: 2px solid #00e091;
  outline: none;
}
.reject-btn {
  border: 2px solid rgb(243, 116, 116);
  background: rgb(243, 116, 116);
}
.reject-btn:hover,
.reject-btn:focus {
  border: 3px solid rgb(243, 116, 116);
  outline: none;
}
.btn-logout {
  background-color: rgb(243, 116, 116);
  border: 2px solid rgb(243, 116, 116);
  background: rgb(243, 116, 116);
}
